import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../app/modules/auth';
import { MenuItem } from './MenuItem';

const UserProfile = ({ userId }: { userId: string }) => {
  const { auth } = useAuth();
  const [companyName, setCompanyName] = useState<string>(''); 
  const [loading, setLoading] = useState<boolean>(false); 
  const [error, setError] = useState<string | null>(null); 

  const url = process.env.REACT_APP_BASE_API_URL; 

  useEffect(() => {
    if (!auth?.data?.userId) return; 
    const apiUrl = `${url?.endsWith('/') ? url.slice(0, -1) : url}/users/${userId || auth?.data?.userId}`;

    setLoading(true); 

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const companyName = data?.companyDetails?.name || 'Unknown Company';
        setCompanyName(companyName); 
        setLoading(false); 
      })
      .catch((error) => {
        setError(error.message); 
        setLoading(false); 
      });
  }, [userId, auth?.data?.userId, url]); 

  if (loading) return <div>Loading...</div>; 
  if (error) return <div>Error: {error}</div>; 

  return (
    <div style={{ fontWeight: 'bold' }}>
      <MenuItem title={`${companyName}`} to="#" />
    </div>
  );
};

export function MenuInner() {
  const { auth } = useAuth();

  return (
    <>
      <UserProfile userId={auth?.data?.userId} />
    </>
  );
}
